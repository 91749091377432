<template>
  <CSidebar
    aside
    :show="$store.state.menuGroupsAsideShow"
    @update:show="(val) => $store.commit('set', ['menuGroupsAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'menuGroupsAsideShow')" />

    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="list-group-accent mt-5" v-show='!showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        {{ item.Name }}
      </CListGroupItem>

      <CListGroupItem v-if="(typeof item.id !== 'undefined')" :to="{ name: 'Menu Group Childs', params: { mnuExtGrpId: item.id, menuGroupName: item.Name }}" class="list-group-item-accent-warning list-group-item-divider">
        <div><strong>Childs</strong> Count</div>
        <h4 class="text-muted mr-3">
          <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.menu_ext_group_childs_count }}
        </h4>
      </CListGroupItem>
      <CListGroupItem class="list-group-item-accent-primary list-group-item-divider">
        <div><strong>Linked Menus</strong> Count</div>
        <h4 class="text-muted mr-3">
          <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.menu_linked_ext_group_count }}
        </h4>
      </CListGroupItem>
      <CListGroupItem class="list-group-item-accent-success list-group-item-divider">
        <div><strong>In Order Items</strong> Count</div>
        <h4 class="text-muted mr-3">
          <CIcon name="cil-arrow-right" class="mr-2" />{{ item.in_order_items_count }}
        </h4>
      </CListGroupItem>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading"/>

  </CSidebar>
</template>

<script>
export default {
  name: "TheAside",
  props: ['id'],
  data() {
    return {
      loading: false,
      item: [],

      // Alert işlemleri için
      errorAlertMessage: '',
      showErrorAlert: false,
    };
  },
  watch: {
    reloadParams() {
      this.getValues();
    },
  },
  computed: {
    reloadParams() {
      return [this.id];
    },
  },
  methods: {
    getValues() {
      this.loading = true;
      this.$axios
        .get(
          this.$backend.MENU_GROUPS.SHOW.replace("{id}", this.id)
        )
        .then((response) => {
          this.item = response.data;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>
