<template>
  <CCardBody>
    <TheAside :id="itemId" />

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Restaurant Filter -->
            <div class="col-lg col-filter mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Select restaurant.."
                v-model="selectedRestaurant"
                :options="allRestaurants"
                @input="inputFilter"
                :disabled="restaurantId"
              />
            </div>

            <!-- Search -->
            <div class="col-lg col-filter mb-2 mb-lg-0">
              <CInput
                class="mb-0"
                type="search"
                placeholder="Search.."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <!-- :active-page="1" -->
    <CDataTable
      clickable-rows
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No menu groups found!',
      }"
      @row-clicked="rowClicked"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >

      <template #id_="{ item }">
        <td class="move-item">
          <CIcon name="cil-move" :data-id="item.id" class="align-bottom" />
        </td>
      </template>

      <template #select="{item}">
        <td>
          <CInputCheckbox
            :checked="item._selected"
            @update:checked="() => check(item)"
            custom
          />
        </td>
      </template>

      <!-- #ID -->
      <template #id="{ item }">
        <td>
          #{{ item.id }}
        </td>
      </template>

      <template #Name="{item}">
        <td>
          <CLink @click="showAside(item)">
            {{ item.Name }}
          </CLink>
        </td>
      </template>

      <template #menu_ext_group_childs_count="{item}">
        <td class="text-center">
          <CLink v-if="(typeof item.id !== 'undefined')" :to="{ name: 'Menu Group Childs', params: { mnuExtGrpId: item.id, menuGroupName: item.Name }}" class="count bg-dark d-inline">
            {{item.menu_ext_group_childs_count}}
          </CLink>
        </td>
      </template>

      <template #menu_linked_ext_group_count="{item}">
        <td class="text-center">
          <span class="count bg-dark d-inline">
            {{item.menu_linked_ext_group_count}}
          </span>
        </td>
      </template>

      <template #actions="{ item, index }">
        <td class="py-2 text-center">
          <CButton size="sm"
            :to="{ name: 'Edit Menu Group', params: { id: item.id }}"
            color="info"
            ><CIcon name="cil-align-left" /></CButton
          >

          <CButton
            size="sm"
            color="danger"
            class="ml-1"
            @click="deleteItem(item, index)"
            ><CIcon name="cil-trash" /></CButton
          >
        </td>
      </template>
    </CDataTable>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div class="action">
            <CButton
              color="success"
              size="sm"
              :disabled="selectedItem === null"
              @click="replicate"
            >
              <CIcon name="cil-clone" class="mb-1"/>&nbsp;Replicate Menu Groups
            </CButton>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="submitted" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>


<script>
import Sortable from "sortablejs";
import TheAside from './TheAside'
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  components: {
    TheAside
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "id_",                         label: "",              sorter: false, filter: false, _style: "width: 1%" },
        { key: "index",                       label: "Index",         filter: false, _style: "width: 4%" },

        { key: "select",                      label: "",              sorter: false, filter: false, _style: "width: 1%" },
        { key: "id",                          label: "#ID",           filter: false, _style: "width: 1%" },

        { key: "Name",                        label: "Name",          filter: false, _style: "min-width: 250px" },
        { key: "Notes",                       label: "Notes",         filter: false, _style: "min-width: 150px" },
        { key: "menu_ext_group_childs_count", label: "Childs",        filter: false, _style: "width: 10%; text-align: center;" },
        { key: "menu_linked_ext_group_count", label: "Linked Menus",  filter: false, _style: "width: 10%; text-align: center;" },
        { key: "actions",                     label: "Actions",       filter: false, sorter: false, _style: "width: 100px; min-width: 100px; text-align: center;" }
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Filter işlemleri için
      allRestaurants: [],
      selectedRestaurant: null,

      // Aside için
      itemId: 0,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Element Cover Spinner için
      submitted: false,
      mcSpinnerMarginTop: 0,

      // Menu Groups replicate (clone) işlemleri için
      selectedItem: null,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  async mounted() {
    // Aside gizle
    this.$store.state.menuGroupsAsideShow = false;

    this.activePage =  this.queryPage;
    this.getAllItems();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }

    await Promise.all([
      this.getRestaurants(),
    ]);

    //#region Sortablejs işlemleri
    var itemID, newIndex;
    var el = document.getElementsByTagName("tbody");

    var sortable = Sortable.create(el[0], {
      handle: "svg.c-icon",
      chosenClass: "table-selected",
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",

      onSort: function (/**Event*/ evt) {
        var itemEl = evt.item; // dragged HTMLElement
        newIndex = evt.newIndex;
        itemID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-id");
        // console.log(itemID, newIndex);
      },
      store: {
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: () =>
          this.storeNewIndexes(
            parseInt(itemID),
            newIndex + ((this.activePage - 1) * this.itemsPerPage)
          ),
      },
    });
    //#endregion Sortablejs ---
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Menu Groups', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    restaurantId() { return this.$route.params.restaurant_id },
  },

  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.MENU_GROUPS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          'restaurant-id': this.selectedRestaurant?.value,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== ""
        )
          url.searchParams.append(key, params[key]);
      });

      // Sortablejs işleminden sonra sayfa geçişlerinde index değerinde göre sıralanmış ögeler
      // düzensiz sırada geliyordu. Bunu önlemek için loadedItems dizisini sıfırlamak gerekti.
      // Sortablejs kullanılmayacaksa aşağıda satır kaldırılabilir.
      this.loadedItems = []

      this.$axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = '';
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getRestaurants() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;

          if(this.restaurantId) {
            this.selectedRestaurant = this.allRestaurants.find(item => item.value == this.restaurantId);
          }
        })
        .catch(() => { this.allRestaurants = [] })
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    rowClicked (item, index, column, e) {
      // INPUT, LABEL ve BUTTON dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A'].includes(e.target.tagName)) {
        this.check(item)
      }
    },
    check (item) {
      this.$set(item, "_selected", !item._selected);
      // v3
      if (item._selected) {
        // Sadece bir adet item seçilsin.
        if(this.selectedItem) {
          this.selectedItem._selected = false;
          this.selectedItem._classes = this.selectedItem._classes.replace(" table-selected", "");
        }

        item._classes = item._classes.concat(" table-selected");
        this.selectedItem = item;
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedItem = null;
      }
    },

    replicate() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to make a copy?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.submitted = true;
            this.$axios.post(this.$backend.MENU_GROUPS.REPLICATE, { 'menu_group_id':this.selectedItem.id })
              .then((response) => {
                this.getAllItems();
                this.showErrorAlert = false;
                this.successAlertMessage = { itemName: 'Success:', message: response.data.success };
                this.dismissSuccessAlert = this.dismissSecs;
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data;
                this.showErrorAlert = true;
              })
              .finally(() => {
                this.submitted = false;
                window.scrollTo(0,0);
                this.selectedItem = null
              });
            // ---------------
          }
        },
      });
    },


    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.MENU_GROUPS.DELETE.replace("{id}", item.id)
              )
              .then((response) => {
                this.$delete(this.loadedItems, index);
                this.showErrorAlert = false
                this.successAlertMessage = {itemName: item.Name, message: 'menu group deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    storeNewIndexes(itemID, newIndex) {
      // console.log(itemID, newIndex);

      if(isNaN(itemID) || isNaN(newIndex))
        return;

      this.loading = true;

      // Dizi index'i 0'dan başlıyor, ancak bizim 0. elemanın index'i 1'den başlıyor.
      newIndex++;

      // For Front-End
      var item = this.loadedItems.find(item => item.id == itemID);
      var oldIndex = item.index;
      if (newIndex > oldIndex) {
        this.loadedItems.filter((item) => {
          return item.index > oldIndex && item.index <= newIndex;
        }).forEach((value, index) => { value.index--; });
      }
      else if (newIndex < oldIndex) {
        this.loadedItems.filter((item) => {
          return item.index < oldIndex && item.index >= newIndex;
        }).forEach((value, index) => { value.index++; });
      }
      item.index = newIndex;

      // For Back-End
      this.$axios
        .get(this.$backend.MENU_GROUPS.STORE_NEW_INDEXES, {
          params: { itemID, newIndex:newIndex },
        })
        // .then(this.getAllItems)
        // .then((response) => {console.log(response);})
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showAside(item) {
      this.itemId = item.id;
      this.$store.commit('toggle', 'menuGroupsAsideShow');
    },

    // Filters
    inputFilter() {
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.search = "";
        !this.restaurantId && (this.selectedRestaurant = null);
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>
